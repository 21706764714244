import React, { useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { dayjs } from '../../../lib/dayjs';
import { isNewsTypeColor } from '../../../lib/is-news-type-color';
import { Layout } from '../../../components/layout';
import { BreadCrumb } from '../../../components/breadcrumb';
import { BackGround } from '../../../components/background';
import { Seo } from '../../../components/seo';
import { SeasonHeader } from '../../../components/season-header';
import * as Styles from '../../../styles/pages/_seasonId.module.scss';
import * as CommonStyles from '../../../styles/global/_common.module.scss';
import {Link, navigate, useIntl} from "gatsby-plugin-react-intl"
import { contentPathHelper } from '../../../utils/contentPathHelper';
import { useCheerio } from '../../../hooks/use-cheerio';

const SeasonSinglePage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const { microcmsSeason, allMicrocmsSeason, allMicrocmsNews } = data;

  const $ = useCheerio(microcmsSeason?.rule)
  const isRuleText = useMemo(() => !!$.text(), [$])

  useEffect(() => {
    if (!isRuleText) {
      navigate(`/season/${microcmsSeason.seasonId}/`)
    }
  }, [isRuleText])

  const postDescription = microcmsSeason.about?.replace(/(<([^>]+)>)/gi, '').substr(0, 70);

  const relatedArticles = () => {
    if (!microcmsSeason.relation.length) {
      return [];
    }

    return allMicrocmsNews.edges.filter(({ node }) => {
      let check = false;

      node.relation.forEach((relation) => {
        microcmsSeason.relation.forEach(({ id }) => {
          if (relation.id === id) {
            check = true;
          }
        });
      });

      return check;
    });
  };

  return (
    <Layout location={location}>
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
          {
            title: t('menu.seasonInformation'),
          },
          {
            title: microcmsSeason?.title,
            link: '/season/' + microcmsSeason?.seasonId + '/',
          },
        ]}
        currentPage={t('season.seasonRules')}
      />
      <Seo title={`${t('season.seasonRules')} | ${microcmsSeason.title}${t('season.seo.title')}`} description={postDescription} />
      <BackGround>
        <SeasonHeader currentSeason={microcmsSeason} seasonList={allMicrocmsSeason.edges} linkList={microcmsSeason.events} link="/season/rule/" location={location} isSeasonRule={isRuleText} />
        <section className={Styles.seasonId}>
          <div className={Styles.seasonId__wrapper}>
            <div className={classNames(CommonStyles.container)}>
              <h2 className={Styles.seasonId__header}>{microcmsSeason.title} {t('season.rules')}</h2>
              <div
                className={Styles.seasonId__rule}
                dangerouslySetInnerHTML={{
                  __html: microcmsSeason?.rule,
                }}
              />

              <h2 className={Styles.seasonId__accent_heading}>関連記事</h2>

              {microcmsSeason ? (
                <ul className={Styles.seasonId__list}>
                  {relatedArticles()
                    .slice(0, 3)
                    .map(({ node }) => (
                      <li key={node.seasonId} className={Styles.seasonId__list_card}>
                        <Link to={`/news/${contentPathHelper(node.newsId)}/`}>
                          <img src={node.image.url} alt="" />
                          <div className={Styles.seasonId__boxwrap}>
                            <div className={Styles.seasonId__set}>
                              {dayjs(node.publishedAt).format('YYYY/MM/DD')}{' '}
                              <p className={Styles.seasonId__newscategory} style={{ backgroundColor: isNewsTypeColor(node.type[0]) }}>
                                {node.type[0]}
                              </p>
                            </div>
                            <p className={Styles.seasonId__newstitle}>{node.title}</p>
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              ) : (
                <p>{t('season.noArticles')}</p>
              )}
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query ($seasonId: String) {
    microcmsSeason(seasonId: { eq: $seasonId }) {
      seasonId
      title
      publishedAt
      about
      rule
      relation {
        id
      }
      events {
        fieldId
        label
        events {
          date
          description
          id
          abstracts {
            fieldId
            theme
          }
          about {
            fieldId
            judge_field {
              fieldId
              image {
                url
              }
              name
              title
            }
            ohter_field {
              content
              fieldId
              title
            }
            prize
            win_lose
          }
          concretes {
            fieldId
            theme
          }
          image {
            url
          }
          publishedAt
          out_date
          in_date
          news_relation {
            id
          }
          participant {
            id
          }
          tournament {
            url
          }
          title
          rule
          type
        }
      }
    }

    allMicrocmsSeason(filter: {is_private: {eq: false}}) {
      edges {
        node {
          seasonId
          title
        }
      }
    }

    allMicrocmsNews(sort: { fields: [publishedAt], order: ASC }) {
      edges {
        node {
          newsId
          image {
            url
          }
          title
          publishedAt
          type
          relation {
            id
            description
          }
        }
      }
    }
  }
`;

export default SeasonSinglePage;
